@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*--------------------------------------------------------------------*/
/*react CSS*/
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/
.App-link {
  color: #61dafb;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------------*/
/*CUSTOM CSS*/

body {
  font-family: 'Raleway', sans-serif;
}
.logo { width: 15vw; }

.bg-trans { background-color: transparent; }

.section-head {
  height: 100vh;
  position: relative;
  /*background-image: url('./img/main.gif');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;*/
}
.section-1-intro {
  /*position: relative;*/
  overflow: hidden;
}
.section-2-head,
.section-3-head {
  height: 100vh;
  position: relative;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
          align-content: flex-start;
}
.section-2-head { background-image: url(/static/media/p2.12c677b8.jpeg); overflow: hidden; }
.section-3-head { background-image: url(/static/media/p3.3746041b.jpg); }
.section-2-body { margin-bottom: 150px; }
.section-3-body {

}
.card-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin: 50px 30px;
}
/*.card-container > div {
  flex: 1 0 50%
}*/

h2 p { margin:0px; }

.trip-title {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0px;
}
.p-abs {
  position:  absolute;
  top: 0;
  left: 0;
}

.phrase p {
  margin: 0;
  text-align: right;
  font-size: 1.3em;
  font-weight: 700;
}
.mobile-link {
  outline: none;
  font-size: 1.1em;
  text-decoration: none;
  color: #000000;
}
.mobile-link:hover {
  background-color: #000000;
  color: #ffffff;
}

@-webkit-keyframes animatedRtL {
  from {
    background-position: 200% 100%;
    opacity: 0;
  }
  to {
    background-position: 100% 100%;
    opacity: 1;
  }
}

@keyframes animatedRtL {
  from {
    background-position: 200% 100%;
    opacity: 0;
  }
  to {
    background-position: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes animatedLtR{
  from {
    background-position: -100% 00%;
    opacity: 0;
  }
  to {
    background-position: 0% 0%;
    opacity: 1;
  }
}
@keyframes animatedLtR{
  from {
    background-position: -100% 00%;
    opacity: 0;
  }
  to {
    background-position: 0% 0%;
    opacity: 1;
  }
}

/*--------------------------------------------------------------------*/
/*material-ui CSS*/
.MuiAppBar-colorPrimary {
  background-color: transparent !important;
}
.MuiBox-root-219 {
  background-color: transparent !important;
}
.MuiPaper-root {
  background-color: transparent !important;
  color: #000000 !important;
}
.MuiTypography-h5,
.MuiTypography-body2,
.MuiButton-root {
  font-family: 'Raleway', sans-serif !important;
}

/*--------------------------------------------------------------------*/
/* menu item hover effect*/
.container--menuitem {
  /*width: 100%;*/
  padding: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.link {
  outline: none;
  text-decoration: none;
  position: relative;
  font-size: 5em;
  line-height: 1;
  color: #000000;
  display: inline-block;
}
.link--menuitem {
  font-weight: 700;
  font-size: 1.2em;
  padding: 0 10px 20px;
  transition: 0.5s;
  z-index: 0;
}
.link--menuitem::after {
  content: '';
  width: 0px;
  height: 2px;
  display: block;
  background: #ffffff;
  transition: 700ms;
}
.link--menuitem:hover::after {
  width: 100%;
}
.link--menuitem:hover {
  color: #ffffff;
}
.link--menuitem:hover::before {
  /*-webkit-transform: rotate3d(0,0,1,135deg) scale3d(1,1,1);
  transform: rotate3d(0,0,1,135deg) scale3d(1,1,1);*/
}
.link--menuitem:hover span {
  /*opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);*/
}
.link--menuitem::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 120%;
  top: 70%;
  margin-top: -18px;
  left: -10%;
  z-index: -1;
  background: #000000;
  -webkit-transform: rotate3d(0,0,1,45deg) scale3d(0,1,1);
  transform: rotate3d(0,0,1,45deg) scale3d(0,1,1);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.link--menuitem span {
  font-size: 50%;
  font-weight: 400;
  position: absolute;
  right: 15px;
  color: #E53369;
  bottom: 0;
  opacity: 0;
  -webkit-transform: translate3d(-10px, -10px, 0);
  transform: translate3d(-10px, -10px, 0);
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

/*--------------------------------------------------------------------*/
/* strikethrough animation effect*/
.letter { 
  font-size: 10em; 
  position: absolute;
  left: calc(30px + 5vh);;
}

@-webkit-keyframes strikeA {
  0%   { width : 0; }
  100% { width: 150%; }
}

@keyframes strikeA {
  0%   { width : 0; }
  100% { width: 150%; }
}
@-webkit-keyframes strikeE {
  0%   { width : 0; }
  100% { width: 130%; }
}
@keyframes strikeE {
  0%   { width : 0; }
  100% { width: 130%; }
}
@-webkit-keyframes strikeC {
  0%   { width : 100%; }
  100% { width: 700%; }
}
@keyframes strikeC {
  0%   { width : 100%; }
  100% { width: 700%; }
}
@-webkit-keyframes strikeA3 {
  0%   { width : 0%; }
  100% { width: 600%; }
}
@keyframes strikeA3 {
  0%   { width : 0%; }
  100% { width: 600%; }
}
@-webkit-keyframes strikeM {
  0%   { width : 0%; }
  100% { width: 327%; }
}
@keyframes strikeM {
  0%   { width : 0%; }
  100% { width: 327%; }
}
@-webkit-keyframes strikeMore {
  0%   { width : 0%; }
  100% { width: 250%; }
}
@keyframes strikeMore {
  0%   { width : 0%; }
  100% { width: 250%; }
}
@-webkit-keyframes strikeBook {
  0%   { width : 0%; }
  100% { width: 160%; }
}
@keyframes strikeBook {
  0%   { width : 0%; }
  100% { width: 160%; }
}
.strike {
  position: relative;
  /*margin: 10px;*/
}
.strike::after {
  content: ' ';
  position: absolute;
  width: 0%;
  background: rgba(0, 0, 0, 1);
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;  
}
.strike-A::after {
  -webkit-animation-name: strikeA;
  animation-name: strikeA;
  height: 10px;
  top: 56%;
  left: 0%; 
}
.strike-E::after {
  -webkit-animation-name: strikeE;
  animation-name: strikeE;
  height: 8px;
  top: 47.2%; 
  left: -120%;
}
.strike-C::after {
  -webkit-animation-name: strikeC;
  animation-name: strikeC;
  height: 10px;
  top: 50%; 
  left: -150%;
}
.strike-A3::after {
  -webkit-animation-name: strikeA3;
  animation-name: strikeA3;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  height: 7px;
  width:0;
  top: 56.5%;
  left: 29%; 
}
.strike-m::after {
  -webkit-animation-name: strikeM;
  animation-name: strikeM;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  width:0;
  height: 1.4px;
  top: 30.9%;
  left: 98%; 
}

.after-strike {
  -webkit-animation-name: textAppear;
  animation-name: textAppear;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  position: absolute;
  right: -450%;
  top: 40%;
}
@-webkit-keyframes textAppear {
  0%   { opacity : 0; }
  100% { opacity: 1; }
}
@keyframes textAppear {
  0%   { opacity : 0; }
  100% { opacity: 1; }
}
.strike-btr::after {
  -webkit-animation-name: btr;
  animation-name: btr;
  height: 2px;
  top: 105%; 
  left: 0%;
}
@-webkit-keyframes btr {
  0%   { width : 0%; }
  100% { width: 78vw; }
}
@keyframes btr {
  0%   { width : 0%; }
  100% { width: 78vw; }
}
.strike-btl::after {
  -webkit-animation-name: btl;
  animation-name: btl;
  height: 2px;
  top: 105%; 
  left: -180%;
}
@-webkit-keyframes btl {
  0%   { width : 0%; }
  100% { width: 240%; }
}
@keyframes btl {
  0%   { width : 0%; }
  100% { width: 240%; }
}
.btn-strike {
  -webkit-animation-name: textAppear;
  animation-name: textAppear;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  position: absolute;
  background-color: #000000 !important;
  color: #ffffff !important;
  text-transform: lowercase !important;
  right: -345%;
  top: -40%;
}
.btn-strike:hover {
  background-color: #1bdcd1 !important;
  color: #000000 !important;
}

.strike-Con::after {
  height: 5px;
  -webkit-animation-name: strikeCon;
  animation-name: strikeCon;
  top: 48%; 
  left: -400%;  
}
@-webkit-keyframes strikeCon {
  0%   { width : 0%; }
  100% { width: 120vw; }
}
@keyframes strikeCon {
  0%   { width : 0%; }
  100% { width: 120vw; }
}
/*--------------------------------------------------------------------*/
/* media queries*/

@media (max-width:600px) {
  .section-head {
    height: 100%;
  }
  .card-container {
    margin: 50px 5px;
  }
  .strike::after {
    /*height: 1px; */
  } 
  .strike-Con::after {
    height: 3px !important;
    left: 0% !important;
  }
  @-webkit-keyframes strikeCon {
    0%   { width : 0%; }
    100% { width: 100vw; }
  }
  @keyframes strikeCon {
    0%   { width : 0%; }
    100% { width: 100vw; }
  }
  @-webkit-keyframes strikeC {
    0%   { width : 100%; }
    100% { width: 450%; }
  }
  @keyframes strikeC {
    0%   { width : 100%; }
    100% { width: 450%; }
  }
  .strike-A::after {
    height: 5px !important;
    top: 56%;
    left: 21%; 
  }
  @-webkit-keyframes strikeA {
    0%   { width : 0; }
    100% { width: 120%; }
  }
  @keyframes strikeA {
    0%   { width : 0; }
    100% { width: 120%; }
  }
  .strike-E::after {
    height: 6px;
    top: 46.6%; 
    left: -50%;
  }
  @-webkit-keyframes strikeE {
    0%   { width : 0; }
    100% { width: 200%; }
  }
  @keyframes strikeE {
    0%   { width : 0; }
    100% { width: 200%; }
  }
  .strike-C::after {
    height: 4px;
    top: 50%; 
    left: -150%;
  }
  @-webkit-keyframes strikeA3 {
    0%   { width : 0%; }
    100% { width: 300%; }
  }
  @keyframes strikeA3 {
    0%   { width : 0%; }
    100% { width: 300%; }
  }
  .strike-A3::after {
    height: 4px;
    top: 57%;
    left: 15%; 
  }
  
  .section-2-head,
  .section-3-head {
    height: 300px;
    background-size: contain !important;
    background-attachment: scroll;
  }
  
  .btn-strike {
    background-color: #000000 !important;
    color: #ffffff !important;
    text-transform: lowercase !important;
    left: 110%;
    top: -45% !important;
  }
  .after-strike {
    right: -200%;
    top: 30%;
  }
  .phrase p {
    font-size: 1em;
    font-weight: 400;
  }
  @-webkit-keyframes strikeM {
    0%   { width : 0%; }
    100% { width: 97%; }
  }
  @keyframes strikeM {
    0%   { width : 0%; }
    100% { width: 97%; }
  }
  .strike-m::after {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    width:0;
    height:0.9px;
    top: 27.7%;
    left: 98%; 
  }  
}

@media (min-width:601px) and (max-width:960px) {
  .section-head {
    height: 100%;
  }
  .section-2-head,
  .section-3-head {
    width: 100%;
    height: 600px;
    background-size: contain !important;
    background-attachment: scroll;
  }
  .strike-A::after {
    height: 5px !important; 
  }
  .strike-E::after {
    height: 6px;
    top: 46.5%; 
    left: -50%;
  }
  @-webkit-keyframes strikeE {
    0%   { width : 0; }
    100% { width: 300%; }
  }
  @keyframes strikeE {
    0%   { width : 0; }
    100% { width: 300%; }
  }
  @-webkit-keyframes strikeC {
  0%   { width : 100%; }
  100% { width: 850%; }
}
  @keyframes strikeC {
  0%   { width : 100%; }
  100% { width: 850%; }
}
  .strike-C::after {
    height: 4px;
    top: 50%; 
    left: -150%;
  }
  .after-strike {
    top: 30%;
    right: -600%;
  }
  .strike-m::after {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    width:0;
    height:1.3px;
    top: 30.9%;
    left: 98%; 
  }
  @-webkit-keyframes strikeM {
    0%   { width : 0%; }
    100% { width: 300%; }
  }
  @keyframes strikeM {
    0%   { width : 0%; }
    100% { width: 300%; }
  }
  .strike-A3::after {
    height: 4px;
    top: 57.2%;
  }
  .btn-strike {
    right: -320%;
  }
}

@media (min-width:961px) and (max-width:1280px) {
  .section-head {
    height: 100%;
  }
  .section-2-head,
  .section-3-head {
    width: 100%;
    height: 800px;
    background-attachment: scroll;
  }
  .after-strike {
    right: -450%;
    top: 35%;
    font-size: 1.5em;
  }
  .strike-m::after {
    top: 31%; 
  }
  @-webkit-keyframes strikeM {
    0%   { width : 0%; }
    100% { width: 327%; }
  }
  @keyframes strikeM {
    0%   { width : 0%; }
    100% { width: 327%; }
  }
  @-webkit-keyframes strikeE {
    0%   { width : 0; }
    100% { width: 300%; }
  }
  @keyframes strikeE {
    0%   { width : 0; }
    100% { width: 300%; }
  }
  @-webkit-keyframes strikeA3 {
    0%   { width : 0%; }
    100% { width: 400%; }
  }
  @keyframes strikeA3 {
    0%   { width : 0%; }
    100% { width: 400%; }
  }
  @-webkit-keyframes strikeCon {
  0%   { width : 0%; }
  100% { width: 130vw; }
}
  @keyframes strikeCon {
  0%   { width : 0%; }
  100% { width: 130vw; }
}
  .strike-A3::after {
    /*height: 4px;
    top: 57%;
    left: 15%; */
  }
}

@media (min-width:1280px) {
  @-webkit-keyframes strikeC {
    0%   { width : 100%; }
    100% { width: 900%; }
  }
  @keyframes strikeC {
    0%   { width : 100%; }
    100% { width: 900%; }
  }
  .after-strike {
    right: -640%;
    top: 35%;
    font-size: 1.5em;
  }
  .strike-C::after {
    left: -160%;
  }
}

